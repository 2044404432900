$(window).scroll(function (){

    //Header Paralax
    var wScroll = $(this).scrollTop();

    var opacity = 1 - (wScroll / 500);

    $('#header-title').css({
        'transform' : 'translate(0px, '+ wScroll / 3 +'px)',
        'opacity': opacity
    });

});
