require('./bootstrap');

window.Noty = require('noty');

window.Arachno = require('./arachnophobia');
window.headerTitle = require('./header-title');
window.ckEditor = require('./ckeditor');

// Run the hello() function
Arachno.hello();


